/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { header_userConnected$ref } from "./header_userConnected.graphql";
export type headerQueryVariables = {||};
export type headerQueryResponse = {|
  +$fragmentRefs: header_userConnected$ref
|};
export type headerQuery = {|
  variables: headerQueryVariables,
  response: headerQueryResponse,
|};
*/


/*
query headerQuery {
  ...header_userConnected
}

fragment avatar_user on User {
  firstname
  lastname
  logo
  avatar {
    nameId
    extension
    id
  }
}

fragment bookmark_book on Bookmark {
  ref
  spaceCounting
  spaceIds
}

fragment header_userConnected on Query {
  userConnected: viewer {
    ref
    fullUserName
    firstname
    lastname
    email
    logo
    ...avatar_user
    bookmark {
      ...bookmark_book
      id
    }
    host
    signup {
      checkData
      step1
      step2
      data
    }
    company {
      name
    }
    hasBookings
    hasSpaces
    corpo {
      ref
      name
      ...layoutCorpoData
      id
    }
    corpoUserConf {
      enable
      status
      isAdmin
      requireValidEmail
    }
    id
  }
}

fragment layoutCorpoData on Corpo {
  ref
  name
  teamName
  formatted_address
  siret
  tvaNumber
  invoiceEmails
  params {
    allowEmailDomain
    automaticApprouval
    manualApprouval
    automaticDeniedOtherEmailDomain
    manualOtherEmailDomain
  }
  payment {
    isSepa
    transferMode
    mandateUrl
  }
  users {
    ref
    roles
    status
    email
    user {
      email
      lastname
      firstname
      id
    }
    adminNeedValid: is(what: NEEDAPPROVAL)
    emails {
      template
      limitDate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ref",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "headerQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "header_userConnected"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "headerQuery",
    "selections": [
      {
        "alias": "userConnected",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullUserName",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Picture",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extension",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Bookmark",
            "kind": "LinkedField",
            "name": "bookmark",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spaceCounting",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spaceIds",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "host",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSignup",
            "kind": "LinkedField",
            "name": "signup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkData",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "step1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "step2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "data",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasBookings",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasSpaces",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Corpo",
            "kind": "LinkedField",
            "name": "corpo",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formatted_address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "siret",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tvaNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "invoiceEmails",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CorpoParams",
                "kind": "LinkedField",
                "name": "params",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowEmailDomain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "automaticApprouval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manualApprouval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "automaticDeniedOtherEmailDomain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manualOtherEmailDomain",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CorpoPayment",
                "kind": "LinkedField",
                "name": "payment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSepa",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "transferMode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mandateUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CorpoUser",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roles",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      (v1/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "adminNeedValid",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "what",
                        "value": "NEEDAPPROVAL"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "is",
                    "storageKey": "is(what:\"NEEDAPPROVAL\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CorpoUserEmail",
                    "kind": "LinkedField",
                    "name": "emails",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "template",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "limitDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CorpoUser",
            "kind": "LinkedField",
            "name": "corpoUserConf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enable",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAdmin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requireValidEmail",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d94f1a101820e1bd39f3ec27cb9eafa9",
    "id": null,
    "metadata": {},
    "name": "headerQuery",
    "operationKind": "query",
    "text": "query headerQuery {\n  ...header_userConnected\n}\n\nfragment avatar_user on User {\n  firstname\n  lastname\n  logo\n  avatar {\n    nameId\n    extension\n    id\n  }\n}\n\nfragment bookmark_book on Bookmark {\n  ref\n  spaceCounting\n  spaceIds\n}\n\nfragment header_userConnected on Query {\n  userConnected: viewer {\n    ref\n    fullUserName\n    firstname\n    lastname\n    email\n    logo\n    ...avatar_user\n    bookmark {\n      ...bookmark_book\n      id\n    }\n    host\n    signup {\n      checkData\n      step1\n      step2\n      data\n    }\n    company {\n      name\n    }\n    hasBookings\n    hasSpaces\n    corpo {\n      ref\n      name\n      ...layoutCorpoData\n      id\n    }\n    corpoUserConf {\n      enable\n      status\n      isAdmin\n      requireValidEmail\n    }\n    id\n  }\n}\n\nfragment layoutCorpoData on Corpo {\n  ref\n  name\n  teamName\n  formatted_address\n  siret\n  tvaNumber\n  invoiceEmails\n  params {\n    allowEmailDomain\n    automaticApprouval\n    manualApprouval\n    automaticDeniedOtherEmailDomain\n    manualOtherEmailDomain\n  }\n  payment {\n    isSepa\n    transferMode\n    mandateUrl\n  }\n  users {\n    ref\n    roles\n    status\n    email\n    user {\n      email\n      lastname\n      firstname\n      id\n    }\n    adminNeedValid: is(what: NEEDAPPROVAL)\n    emails {\n      template\n      limitDate\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ab68c1d5f51578b0406d3d6f27a68b3';

module.exports = node;
