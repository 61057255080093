// https://swizec.com/blog/usedimensions-a-react-hook-to-measure-dom-nodes
/* eslint-disable camelcase */

import { createFragmentContainer, graphql } from 'react-relay';
import Image from 'next/image';
import { useZusHomeStore } from '@/helpers/context';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';
import { ucfirst } from '@/helpers/common';

const query = graphql`
  fragment avatar_user on User {
    firstname
    lastname
    logo
    avatar {
      nameId
      extension
    }
  }
`;
function Avatar({
 className, user, onClick, renderAvatar,
}) {
  const userConnected = useZusUserConnectedStore((state) => state.userData);

  if (!user) return null;

  const { extWebpJpg: extension } = useZusHomeStore.getState();

  let newProps = {
    className,
  };
  if (onClick) {
    newProps = {
      ...newProps,
      onClick,
    };
  }

  function renderLogo() {
    if (!user.avatar || !user.avatar.nameId || !user.avatar.extension) {
      return (
        <span className="flex items-center justify-center h-full font-medium bg-white-dark2 text-black-light">
          {(user?.firstname || '').trim()[0]}
        </span>
      );
    }
    const imageUrl = `https://i.officeriders.com/zone/${user.avatar.nameId}-60-60-${user.avatar.extension}.${extension}`;
    return (
      <Image priority fill style={{ objectFit: 'cover' }} alt={`Avatar ${user?.firstname}`} src={imageUrl} />
    );
  }

  function renderAvatarImage() {
    if (user?.logo) {
      return <Image priority fill style={{ objectFit: 'contain' }} alt={`Logo ${user?.firstname}`} src={`${user.logo}?size=126`} />;
    } if (userConnected?.corpo?.name) {
      return <div className="flex items-center justify-center w-full h-full bg-black text-white-dark2">{(ucfirst(userConnected?.corpo?.name) || '').trim()[0]}</div>;
    }
    return null;
  }

  function renderAvatarOrCompanyLogo() {
    if (renderAvatar) {
      return renderAvatarImage();
    }
      return renderLogo();
  }

  return (
    <div {...newProps}>
      {renderAvatarOrCompanyLogo()}
    </div>
  );
}
export { Avatar };

export default createFragmentContainer(Avatar, { user: query });
